import axios from "axios";

const api = axios.create({
  //baseURL: 'http://localhost:4000',
  //baseURL: "http://192.168.0.100:4000",
  baseURL: "https://api.jotajoti.com.br",
  /* baseURL: "https://89ab-2804-7f4-8280-4786-c913-9292-8422-2bbf.sa.ngrok.io/", */
});

export default api;
