import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",

    /* [theme.breakpoints.down('md')]: { */
    background: "#674bd1",
    /*  }, */
  },
}));
