import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export default makeStyles((theme) => ({
  paper: {
    background: "#FBFBFB",
    boxShadow: "3px 3px 4px #0000000F",
    borderRadius: 0,
    padding: "50px 20px",
  },

  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  title: {
    width: "100%",
    textAlign: "center",
    fontSize: 22,
    color: "#363636",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",
  },

  btn1: {
    background: "#664ACF",
    "&:hover": {
      background: darken(0.08, "#664ACF"),
    },
    borderRadius: 3,
    color: "#fff",
    fontSize: 18,
    textTransform: "capitalize",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      width: "100%",
    },
  },
}));
