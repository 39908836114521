import React from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Avatar, Typography, IconButton } from "@material-ui/core";
import { Done, DoneAll } from "@material-ui/icons";
import clsx from "clsx";
import Gravatar from "react-gravatar";
import useStyles from "./styles";

function User({ data }) {
  const classes = useStyles();
  const me = useSelector((state) => state.user.me);

  function preData() {
    if (String(data.user._id) === String(me._id)) return data.requester;

    return data.user;
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginBottom: 20 }}>
      <Paper
        className={clsx(
          classes.paper,
          (!data.checked.user || !data.checked.requester) && classes.red
        )}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                <Gravatar
                  email={preData().email}
                  className={classes.profileImg}
                  size={150}
                />
              </Grid>
              <Grid item>
                {data.checked.user && data.checked.requester && (
                  <IconButton>
                    <Done />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.name} noWrap>
              {preData().name}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.register}>
              {`Código: ${preData().random || "Sem Código"}`} <br />
              {`Indicativo: ${preData().indicativo || "Sem Indicativo"}`}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default User;
