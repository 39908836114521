import MaskedInput from "react-text-mask";
import React from "react";

export default function TextMaskIndicativo(props) {
  const { inputRef, guide, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(value) => {
        if (value.match(/[A-Z|a-z][A-Z|a-z][0-9][0-9]/g)) {
          return [
            /[A-Z|a-z]/,
            /[A-Z|a-z]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[A-Z|a-z]/,
            /[A-Z|a-z]/,
            /[A-Z|a-z]/,
          ];
        }

        if (
          value.match(/[A-Z|a-z][A-Z|a-z][0-9][A-Z|a-z][A-Z|a-z]/g) ||
          value.match(/[A-Z|a-z][A-Z|a-z][0-9][A-Z|a-z][A-Z|a-z][A-Z|a-z]/g)
        ) {
          return [
            /[A-Z|a-z]/,
            /[A-Z|a-z]/,
            /[0-9]/,
            /[A-Z|a-z]/,
            /[A-Z|a-z]?/,
            /[A-Z|a-z]?/,
          ];
        }

        return [
          /[A-Z|a-z]/,
          /[A-Z|a-z]/,
          /[0-9]/,
          /[A-Z|a-z]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];
      }}
    />
  );
}
