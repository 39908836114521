import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import useStyles from './styles';
import questIcon from '../../../../assets/icons/questIcon.svg';

// import { Container } from './styles';

export default function Task({ number }) {
  const classes = useStyles();

  return (
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <Button fullWidth style={{ height: '100%' }}>
        <Grid
          container
          spacing={1}
          alignContent="space-between"
          style={{ width: '100%', height: '100%' }}
        >
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            style={{ display: 'flex' }}
          >
            <div className={classes.circle}>
              <div className={classes.circleInside}>
                <img src={questIcon} className={classes.icon} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center">
            <Typography className={classes.name}>Desafio {number}</Typography>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center">
            <Typography className={classes.status}>Não publicada</Typography>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
}
