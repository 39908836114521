/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeRequest } from "../../../../../store/modules/user/actions";
import {
  Dialog,
  Typography,
  Button,
  Grid,
  CircularProgress,
  withStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import useStyles from "./styles";
import { toast } from "react-toastify";
import api from "../../../../../services/api";
import clsx from "clsx";

import Radio from "@material-ui/core/Radio";

const CustomRadio = withStyles({
  root: {
    color: "#744EAA",
    "&$checked": {
      color: "#744EAA",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CodeTeam = ({ open, close }) => {
  const me = useSelector((state) => state.user.me);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [teams, setTeams] = React.useState([]);
  const [teamSelected, setTeam] = React.useState(null);

  async function handle() {
    try {
      setLoading(true);

      await api.post("/team/join", {
        teamId: teamSelected,
      });

      dispatch(getMeRequest());
      close();

      toast.success("Voce entrou no time com sucesso.");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro,tente novamente mais tarde"
      );
    }
  }

  async function getTeams() {
    try {
      setLoading(true);

      const response = await api.get(`/team/list-by-group/${me.groupNumber}`, {
        params: {
          region: me.region,
        },
      });

      setTeams(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function selectTeam(event) {
    setTeam(event.target.value);
  }

  React.useEffect(() => {
    if (open) {
      setTeam(null);
      getTeams();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xs"
      style={{ overflowX: "hidden" }}
    >
      <Grid
        container
        spacing={2}
        className={classes.paper}
        style={{ overflowY: "scroll" }}
      >
        <Grid item xs={12}>
          <Typography className={classes.title}>
            Encontrar equipes do seu grupo
          </Typography>
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <div className={classes.loading}>
              <CircularProgress className={classes.circular} size={30} />
            </div>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              {teams.length === 0 ? (
                <Typography className={classes.text}>
                  Seu grupo ainda não criou uma equipe!
                </Typography>
              ) : (
                <FormControl component="fieldset">
                  <RadioGroup
                    value={teamSelected}
                    onChange={(event) => {
                      selectTeam(event);
                    }}
                  >
                    {teams.map((team, index) => (
                      <FormControlLabel
                        value={team._id}
                        control={
                          <CustomRadio checked={teamSelected === team._id} />
                        }
                        label={`${index + 1} - ${team.name}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>

            {teamSelected && (
              <Grid item xs={12}>
                <Button
                  onClick={() => handle()}
                  fullWidth
                  className={classes.button1}
                >
                  Entrar
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                onClick={() => close()}
                fullWidth
                className={clsx(classes.button1, classes.red)}
              >
                Cancelar
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  );
};

export default CodeTeam;
