import { makeStyles } from '@material-ui/core/styles';
import { darken } from 'polished';

export default makeStyles((theme) => ({
  circle: {
    border: '10px solid #CECECE',
    padding: 10,
    borderRadius: 126,
    width: 126,
    height: 126,

    [theme.breakpoints.down('sm')]: {
      border: '5px solid #CECECE',
      padding: 7,
      borderRadius: 71,
      width: 71,
      height: 71,
    },
  },

  circleInside: {
    backgroundColor: '#CECECE',
    borderRadius: 100,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
    width: '100%',
    height: '100%',
  },

  icon: {
    width: '100%',
    height: '100%',
  },

  name: {
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    color: '#807979',
    textAlign: 'center',
  },

  status: {
    fontWeight: 400,
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    color: '#807979',
    textAlign: 'center',
  },

  button: {
    height: '100%',
  },
}));
