import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    background: "#FBFBFB",
    boxShadow: "3px 3px 4px #0000000F",
    borderRadius: 0,
    padding: "50px 20px",

    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      boxShadow: "none",
    },
  },

  card: {
    background: "#fff",
    borderRadius: 3,
    boxShadow: "none",
    padding: "20px 50px !important",
    minHeight: "100%",
  },

  title: {
    letterSpacing: 0.66,
    fontSize: 22,
    color: "#664ACF",
    width: "100%",
    textAlign: "center",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",

    [theme.breakpoints.down("sm")]: {
      color: "#363636",
    },
  },

  text: {
    letterSpacing: 0.66,
    fontSize: 16,
    color: "#363636",
    width: "100%",
    textAlign: "center",
    fontWeight: 300,
    fontFamily: "Source Sans Pro",
  },

  button: {},
}));
