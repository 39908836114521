import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import Team from './Team';
import Profile from './Profile';
import TeamUsers from './TeamUsers';

import useStyles from './styles';

// import { Container } from './styles';

export default function Dasboard() {
  const classes = useStyles();
  const team = useSelector((state) => state.team.team);
  const loadingtTeam = useSelector((state) => state.team.loading);

  return (
    <Grid container spacing={5} className={classes.padding}>
      <Grid item xs={12} className={classes.padding}>
        <Team />
      </Grid>
      
      <Grid item xs={12} className={classes.padding}>
        <Profile />
      </Grid>
      <Grid item xs={12} className={classes.padding}>
        {team && !loadingtTeam && <TeamUsers />}
      </Grid>
    </Grid>
  );
}
