import React from "react";
import { useSelector } from "react-redux";
import { Paper, Grid, Typography, Hidden } from "@material-ui/core";

import User from "./User";

import useStyles from "./styles";

export default function TeamUsers() {
  const classes = useStyles();

  const team = useSelector((state) => state.team.team);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Hidden sm smDown>
                <Typography className={classes.title}>
                  Participantes da sua Equipe
                </Typography>
              </Hidden>

              <Hidden md mdUp>
                <Typography className={classes.titleMobile}>
                  Participantes
                </Typography>
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {team &&
                  team.users.map((user) => <User data={user} key={user._id} />)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
