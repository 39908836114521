import * as Yup from "yup";

export default async (data) => {
  const schema = Yup.object().shape({
    name: Yup.string().required("Por favor, digite um nome."),
    email: Yup.string()
      .email("Digite uma e-mail valido")
      .required("Por favor, digite um email."),
    date: Yup.string().required("Por favor, digite uma data valida."),
  });

  await schema.validate(data, { abortEarly: false });
};
