import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Avatar,
  Typography,
  IconButton,
  CircularProgress,
  Hidden,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { DeleteOutline, MenuOpenOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import Modal from "../../../../../components/Modal";

import useStyles from "./styles";

import { getTeamRequest } from "../../../../../store/modules/Team/actions";
import { getMeRequest } from "../../../../../store/modules/user/actions";
import api from "../../../../../services/api";
import Gravatar from "react-gravatar";

// import { Container } from './styles';

export default function User({ data }) {
  const me = useSelector((state) => state.user.me);
  const team = useSelector((state) => state.team.team);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  async function exclue() {
    setLoading1(true);

    try {
      await api.post("/team/quit-force", {
        userId: data._id,
        teamId: team.teamId,
      });

      setLoading1(false);

      toast.success("Usuario retirado da equipe");
      dispatch(getTeamRequest());
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro,tente novamente mais tarde"
      );
      dispatch(getMeRequest());
      setLoading1(false);
    }
  }

  function cutName(name) {
    if (name.length < 10) return name;

    return String(name).substring(0, 7) + "...";
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleChange(type) {
    setAnchorEl(null);

    try {
      setLoading2(true);

      await api.put(`/user/level/${team.teamId}`, {
        userId: data._id,
        newLevel: type,
      });

      setLoading2(false);

      toast.success(`Usuario alterado para ${type} com sucesso`);
      dispatch(getTeamRequest());
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro,tente novamente mais tarde"
      );
      dispatch(getMeRequest());
      setLoading2(false);
    }
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg={3}>
      <Hidden sm smDown>
        <Paper
          className={classes.paper}
          style={
            me.team && me.team.level === "coordenador"
              ? { height: "100%", padding: "30px 25px 10px 25px" }
              : { height: "100%", padding: "30px 25px 25px 25px" }
          }
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Gravatar
                    email={data.lgpd ? data.email : null}
                    className={classes.avatar}
                    size={150}
                  />
                </Grid>
                <Grid item xs zeroMinWidth>
                  {data.lgpd ? (
                    <>
                      <Typography className={classes.name} noWrap>
                        {data && data.name.toLowerCase()}
                      </Typography>

                      <Typography className={classes.level} noWrap>
                        {data && data.team && data.team.level}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.level}>
                      Ainda não aceitou a lgpd
                    </Typography>
                  )}
                  <Typography className={classes.level} noWrap>
                    {data && data.register}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {me._id !== data.userId &&
              me.team &&
              me.team.level === "coordenador" && (
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Grid item>
                      {loading2 ? (
                        <CircularProgress
                          style={{ color: "#664ACF" }}
                          size={25}
                        />
                      ) : (
                        <IconButton onClick={handleClick}>
                          <MenuOpenOutlined style={{ color: "#664ACF" }} />
                        </IconButton>
                      )}
                    </Grid>

                    <Grid item>
                      {loading1 ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#363636" }}
                        />
                      ) : (
                        <IconButton onClick={() => setOpenDelete(true)}>
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Paper>
      </Hidden>

      <Hidden md mdUp>
        <Grid
          container
          spacing={1}
          wrap="nowrap"
          alignItems="center"
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          <Grid item>
            <Grid
              container
              spacing={2}
              style={{ flex: 1 }}
              alignItems="center"
              wrap="nowrap"
            >
              {data.lgpd && (
                <Grid item>
                  <Gravatar
                    email={data.email}
                    className={classes.avatar}
                    size={150}
                  />
                </Grid>
              )}
              <Grid item>
                {data.lgpd ? (
                  <>
                    <Typography className={classes.nameMobile} noWrap>
                      {data && cutName(cutName(data.name)).toLowerCase()}
                    </Typography>

                    <Typography noWrap className={classes.levelMobile}>
                      {data && data.team && data.team.level}
                    </Typography>
                  </>
                ) : (
                  <Typography noWrap className={classes.levelMobile}>
                    Ainda não aceitou a lgpd
                  </Typography>
                )}

                <Typography noWrap className={classes.levelMobile}>
                  {data && data.register}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {me._id !== data.userId &&
            me.team &&
            me.team.level === "coordenador" && (
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justify="flex-end"
                  wrap="nowrap"
                >
                  <Grid item>
                    {loading2 ? (
                      <CircularProgress
                        style={{ color: "#664ACF" }}
                        size={25}
                      />
                    ) : (
                      <IconButton onClick={handleClick}>
                        <MenuOpenOutlined
                          style={{ color: "#664ACF" }}
                          className={classes.iconMobile}
                        />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item>
                    {loading1 ? (
                      <CircularProgress
                        size={25}
                        style={{ color: "#363636" }}
                      />
                    ) : (
                      <IconButton onClick={() => setOpenDelete(true)}>
                        <DeleteOutline className={classes.iconMobile} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
        <div className={classes.divider} />
      </Hidden>

      <Modal
        open={openDelete}
        close={() => setOpenDelete(false)}
        handle={() => exclue()}
        title="Remover participante"
        text="Tem certeza que deseja remover esse membro da sua equipe?"
        bt1Text="Remover"
        bt2Text="Cancelar"
      />

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChange("coordenador")}>
          Coordernador
        </MenuItem>
        <MenuItem onClick={() => handleChange("participante")}>
          Participante
        </MenuItem>
      </Menu>
    </Grid>
  );
}
