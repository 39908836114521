import * as React from "react";

import Bronze from "./Components/Bronze";
import Prata from "./Components/Prata";
import Ouro from "./Components/Ouro";
import Diamante from "./Components/Diamante";

function Certificate({ name, coordinator, classification, ...rest }) {
  if (!name || !coordinator) {
    return (
      <h1 style={{ width: "100%", textAlign: "center" }}>
        {" "}
        Sem certificado disponivel{" "}
      </h1>
    );
  }

  if (classification === "Bronze")
    return <Bronze name={name} coordinator={coordinator} />;

  if (classification === "Prata")
    return <Prata name={name} coordinator={coordinator} />;

  if (classification === "Ouro")
    return <Ouro name={name} coordinator={coordinator} />;
  if (classification === "Sem pontos")
    return (
      <h1 style={{ width: "100%", textAlign: "center" }}>
        {" "}
        Sem certificado disponivel{" "}
      </h1>
    );
  return <Diamante name={name} coordinator={coordinator} />;
}

export default Certificate;
