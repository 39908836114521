import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";

import CloseIcon from "@material-ui/icons/Close";

import { signOut } from "../../store/modules/auth/actions";

import { getMeSuccess } from "../../store/modules/user/actions";

import api from "../../services/api";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const me = useSelector((state) => state.user.me);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  async function accept() {
    try {
      setLoading(true);

      const response = await api.put(`/user/${me._id}/lgpd`, {
        lgpd: true,
      });

      dispatch(getMeSuccess(response.data));
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro, tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  }

  function refuse() {
    dispatch(signOut());
  }

  return (
    <Dialog
      onClose={() => {}}
      aria-labelledby="customized-dialog-title"
      open={!me?.lgpd}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={refuse}>
        LGPD
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Participante ou Responsável,
          <br /> <br />
          Para a realização do cadastro na Plataforma do JotaJoti Brasil é
          necessário fornecer alguns dados pessoais. Esses dados serão usados
          para a criação do seu perfil na plataforma e liberação de funções.
          Usaremos os mesmos apenas dentro da plataforma e não repassaremos para
          terceiros e nenhum outro serviço fora da plataforma do JotaJoti
          Brasil. A plataforma ficará disponível por até 6 (seis) meses após a
          realização da atividade, com o transcorrer desse período todas as
          informações serão permanentemente excluídas de nossa base de dados.
          Iremos recolher as informações diretamente do sistema Paxtu.
        </Typography>
        <Typography gutterBottom>
          <br />
          <br />
          Informações Recolhidas:
          <br />
          <br />
          · Nome
          <br />
          · Registro Escoteiro
          <br />
          · Data de Nascimento
          <br />
          · Região Escoteira
          <br />
          · Numeral do Grupo
          <br />
          · Ramo (Lobinho, Escoteiro, Sênior, Pioneiro).
          <br />· E-mail
          <br />
          <br />
        </Typography>
        <Typography gutterBottom>
          Para utilizar você precisar autorizar o tratamento dos dados
          mencionados acima, bem como compreende a finalidade do tratamento dos
          dados e autoriza que os dados sejam utilizados durante o tempo
          mencionado pela equipe do JotaJoti Brasil, restringindo-se às funções
          e atividades por cada um desempenhadas e posteriormente devidamente
          excluído.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={refuse} color="secondary" variant="contained">
          Não concordar
        </Button>
        <Button onClick={() => accept()} color="primary" variant="contained">
          {loading ? <CircularProgress size={25} /> : "Concordar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
