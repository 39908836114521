import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Grid,
  Typography,
  Hidden,
  Button,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { toast } from "react-toastify";
import Modal from "../../../../components/Modal";
import { getCountryName } from "../../../../utils/countries";

import useStyles from "./styles";

import { getMeRequest } from "../../../../store/modules/user/actions";
import api from "../../../../services/api";

import ModalIndicativo from "./ModalIndicativo";

export default function Profile() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const me = useSelector((state) => state.user.me);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [open3, setOpen3] = useState(false);

  async function att() {
    dispatch(getMeRequest());
  }

  async function outTeam() {
    setOpen(false);
    setLoading(true);
    try {
      await api.post("/team/quit");
      att();
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro,tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  }

  async function getRandom() {
    try {
      setLoading(true);

      await api.put(`/user/${me._id}`, {
        random: true,
      });
      att();
    } catch (error) {
      toast.error(
        error.response.data.error ||
          "Ocorreu algum erro ,tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography className={classes.title}>
                Informações Pessoais
              </Typography>
            </Grid>

            <Hidden sm smDown>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2} justify="center">
                  {!me?.random && (
                    <Grid item>
                      <Button className={classes.btn1} onClick={getRandom}>
                        Gerar Código
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      className={classes.btn2}
                      onClick={() => setOpen3(true)}
                    >
                      {`${me?.indicativo ? "Alterar" : "Adicionar"} Indicativo`}
                    </Button>
                  </Grid>

                  {me && me.team && me.team.teamId && (
                    <Grid item>
                      {loading ? (
                        <CircularProgress style={{ color: "#363636" }} />
                      ) : (
                        <Button
                          className={classes.btn2}
                          disabled={loading}
                          onClick={() => setOpen(true)}
                        >
                          Sair da equipe
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.label)}>Nome:</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.value)}>
                    {me && me.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.label)}>
                    Registro:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.value)}>
                    {me && me.register}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.label)}>Ramo:</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.value)}>
                    {me && me.session}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.label)}>
                    Unidade Escoteira:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.value)}>
                    {me && me.groupName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.label)}>
                    Região:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography className={clsx(classes.value)}>
                    {me && me.region}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {me && me.team && me.team.teamId && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography className={clsx(classes.label)}>
                      Nivel:
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography className={clsx(classes.value)}>
                      {me && me.team.level}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {loading ? (
              <CircularProgress style={{ color: "#363636" }} />
            ) : (
              <>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item sm={6}>
                      <Typography className={clsx(classes.label)}>
                        Código:
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography className={clsx(classes.value)}>
                        {me?.random || "Sem código registrado"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item sm={6}>
                      <Typography className={clsx(classes.label)}>
                        País:
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography className={clsx(classes.value)}>
                        {me?.country ? getCountryName(me.country) : "Brasil"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item sm={6}>
                      <Typography className={clsx(classes.label)}>
                        Indicativo:
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography className={clsx(classes.value)}>
                        {me?.indicativo || "Sem indicativo registrado"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        <Hidden md mdUp>
          <Grid item xs={12}>
            <Grid container spacing={2} justify="space-evenly">
              {me && me.team && me.team.teamId && (
                <Grid item xs={6}>
                  {loading ? (
                    <CircularProgress style={{ color: "#363636" }} />
                  ) : (
                    <Button
                      fullWidth
                      className={classes.btn2}
                      disabled={loading}
                      onClick={() => setOpen(true)}
                    >
                      Sair da equipe
                    </Button>
                  )}
                </Grid>
              )}

              <Grid item xs={6}>
                <Button fullWidth className={classes.btn1} onClick={getRandom}>
                  Gerar Código
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth
                  className={classes.btn2}
                  onClick={() => setOpen3(true)}
                >
                  {`${me?.indicativo ? "Alterar" : "Adicionar"} Indicativo`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>

      <Modal
        open={open}
        close={() => setOpen(false)}
        handle={() => outTeam()}
        title="Sair da equipe"
        text="Tem certeza que deseja sair desta equipe?"
        bt1Text="Sair"
        bt2Text="Cancelar"
      />

      <ModalIndicativo open={open3} close={() => setOpen3(false)} />
    </Paper>
  );
}
