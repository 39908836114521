import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";

import Dashboard from "../pages/Default/Dasboard";
import Support from "../pages/Default/Support";
import Tasks from "../pages/Default/Tasks";
import TaskRadio from "../pages/Default/TaskRadio";
import Organization from "../pages/Default/Organization";
import Figurines from "../pages/Default/figurines";
import Points from "../pages/Default/Points";
import Certificate from "../pages/Default/Certificate";
import CertificateFigurines from "../pages/Default/Certificate/Figurines";

import Teams from "../pages/Default/Teams";

export default function Routes() {
  return (
    <Switch>
      {/* Auth Routes */}
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />

      {/* Academy Routes */}
      <Route path="/dashboard" exact isPrivate component={Dashboard} />
      <Route path="/desafios-joti" exact isPrivate component={Tasks} />
      <Route path="/desafios-jota" exact isPrivate component={TaskRadio} />
      <Route path="/support" exact isPrivate component={Support} />
      {/* <Route path="/organization" exact isPrivate component={Organization} /> */}
      <Route path="/insignia-remember" exact isPrivate component={Figurines} />
      <Route path="/points" exact isPrivate component={Points} />
      <Route path="/certificate" exact isPrivate component={Certificate} />

      <Route path="/teams" exact isPrivate component={Teams} />
      {/*  <Route
        path="/certificate/figurine"
        exact
        isPrivate
        component={Certificateinsignia-remembers}
      /> */}

      <Route path="/" component={Dashboard} />
    </Switch>
  );
}
