import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    background: "#fff",
    borderRadius: 3,
    boxShadow: "none",
    padding: "20px 50px !important",
    height: "100% !important",

    [theme.breakpoints.down("sm")]: {
      background: "#FBFBFB",
      padding: "20px !important",
    },
  },

  title: {
    letterSpacing: 0.66,
    fontSize: 22,
    color: "#664ACF",
    width: "100%",
    textAlign: "center",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: 15,
    },
  },

  text: {
    letterSpacing: 0.66,
    fontSize: 16,
    color: "#363636",
    width: "100%",
    textAlign: "center",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    marginBottom: 20,

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginBottom: 0,
      textAlign: "left",
    },
  },

  pic: {
    width: 128,
    height: 128,
    borderRadius: 64,
    margin: "0 auto",

    [theme.breakpoints.down("sm")]: {
      height: 70,
      width: 70,
      borderRadius: 35,
    },
  },

  divPic: {
    display: "flex",
    marginBottom: 20,
  },

  button: {},
}));
