import { makeStyles } from '@material-ui/core/styles';
import { darken } from 'polished';

export default makeStyles((theme) => ({
  paper: {
    background: '#FBFBFB',
    boxShadow: '3px 3px 4px #0000000F',
    borderRadius: 0,
    padding: '10px',
    width: '100%',
    display: 'flex',
  },
  name: {
    width: '100%',
    textAlign: 'left',
    fontSize: 18,
    letterSpacing: 0.84,
    color: '#363636',
    opacity: 1,
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',

    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },

  register: {
    width: '100%',
    textAlign: 'left',
    fontSize: 16,
    letterSpacing: 0.84,
    color: '#363636',
    opacity: 1,
    fontWeight: 500,
    fontFamily: 'Source Sans Pro',

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },

  profileImg: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },

  red: {
    background: '#f48fb1',
  },
}));
