import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    boxShadow: 'none',
    overflow: ' hidden',
  },

  profile: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  avatar: {
    width: 50,
    height: 50,
    borderRadius: 50,
    marginRight: 10,
  },

  info: {},

  name: {
    letterSpacing: 0.66,
    fontSize: 18,
    color: '#363636',
    width: '100%',
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
    textTransform: 'capitalize',
  },

  nameMobile: {
    letterSpacing: 0.66,
    fontSize: 16,
    color: '#363636',
    width: '100%',
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
    textTransform: 'capitalize',
  },

  level: {
    letterSpacing: 0.66,
    fontSize: 18,
    color: '#363636',
    width: '100%',
    fontWeight: 400,
    fontFamily: 'Source Sans Pro',
  },

  levelMobile: {
    letterSpacing: 0.66,
    fontSize: 14,
    color: '#363636',
    width: '100%',
    fontWeight: 400,
    fontFamily: 'Source Sans Pro',
  },

  iconMobile: {
    fontSize: 20,
  },

  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#C8BCFD',
    marginTop: 9,
  },
}));
