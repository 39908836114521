import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeRequest } from "../../../../../store/modules/user/actions";
import {
  Dialog,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import { Form } from "@unform/web";
import Input from "../../../../../components/Input";
import * as Yup from "yup";
import validator from "./validator";
import { toast } from "react-toastify";
import api from "../../../../../services/api";
import clsx from "clsx";

const CodeTeam = ({ open, close }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const team = useSelector((state) => state.team.team);

  async function handle(data) {
    formRef.current.setErrors({});

    try {
      setLoading(true);
      await validator(data);

      await api.put("/team", {
        ...data,
        teamId: team._id,
      });

      dispatch(getMeRequest());
      close();

      setLoading(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      }

      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xs"
      style={{ overflowX: "hidden" }}
    >
      <Grid container spacing={2} className={classes.paper}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            Editar informaçōes da sua equipe
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.text}>
            Altere o nome ou a descrição da sua equipe aqui!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Form ref={formRef} onSubmit={handle} initialData={team || {}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input name="name" label="Nome" />
              </Grid>
              <Grid item xs={12}>
                <Input name="description" label="Descrição" />
              </Grid>
            </Grid>
          </Form>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => formRef.current.submitForm()}
            fullWidth
            className={classes.button1}
          >
            {loading ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.circular} size={30} />
              </div>
            ) : (
              "Editar"
            )}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => close()}
            fullWidth
            className={clsx(classes.button1, classes.red)}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CodeTeam;
