import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  padding: {
    [theme.breakpoints.between('xs', 'sm')]: {
      /*  paddingLeft: '0px !important',
      paddingRight: '0px !important', */
    },
  },
}));
