import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Container,
  Grid,
  Paper,
  Button,
  Typography,
  Hidden,
  CircularProgress,
  Link,
  Tooltip,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import logo from "../../../assets/logo2023Color.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import history from "../../../services/history";
import { signInRequest } from "../../../store/modules/auth/actions";
import useStyles from "./styles";

import farb from "../../../assets/icons/farb.svg";

import Input from "../../../components/Input";

import { Form } from "@unform/web";
import * as Yup from "yup";
import validator from "./validators";
import api from "../../../services/api";

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const classes = useStyles();
  const formRef = useRef();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const [loadingResetEmail, setLoadingResetEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handlechange = (e, name) => {
    setData({ ...data, [name]: e.target.value });
  };

  async function submit() {
    formRef.current.setErrors({});

    try {
      await validator(data);

      dispatch(signInRequest(data));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const resetPassword = async () => {
    if (!data.email) {
      return toast.error(
        "Insira o registro(brasileiro UEB) ou e-mail(não associado/externo) para recuperar a senha"
      );
    }

    try {
      setLoadingResetPassword(true);

      const response = await api.post("/user/reset-password", {
        register: data.email,
      });
      return toast.success(
        `E-mail com nova senha enviado para: ${response.data.email}`
      );
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro, tente novamente mais tarde!"
      );
    } finally {
      setLoadingResetPassword(false);
    }
  };

  const resetEmail = async () => {
    if (!data.email) {
      return toast.error(
        "Insira o registro(brasileiro UEB) ou e-mail(não associado/externo) para recuperar a senha"
      );
    }

    try {
      setLoadingResetEmail(true);

      const response = await api.put(`/user/updatePaxtu/${data.email}`);

      return toast.success(
        `Sincronização realizada com sucesso! E-mail: ${response.data.email}`
      );
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro, tente novamente mais tarde!"
      );
    } finally {
      setLoadingResetEmail(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img className={classes.logo} src={logo} />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.descriptionDiv}>
            <Typography className={classes.description}>
              Bem-vindo(a) ao JOTA-JOTI Challenge 2023!
            </Typography>
            <Hidden smDown>
              <Typography className={classes.description}>
                Preencha os campos para entrar na plataforma
              </Typography>
            </Hidden>
          </Grid>

          <Grid item xs={12}>
            <Form ref={formRef} onSubmit={submit} className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    name="email"
                    label="Registro ou e-mail"
                    placeholder="Sem o dígito verificador"
                    degfaultValue={data.email}
                    value={data.email}
                    onChange={(e) => handlechange(e, "email")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    name="password"
                    label="Senha"
                    value={data.password}
                    onChange={(e) => handlechange(e, "password")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  {loading ? (
                    <div className={classes.loading}>
                      <CircularProgress
                        style={{ color: "#744EAA" }}
                        size={40}
                      />
                    </div>
                  ) : (
                    <Button
                      className={classes.btn1}
                      fullWidth
                      disabled={loading}
                      type="submit"
                    >
                      Entrar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                className={classes.sup}
                href="https://suporte.jotajoti.com.br/"
                target="_blank"
              >
                Suporte
              </a>
              <a
                className={classes.sup}
                href="https://stats.uptimerobot.com/RL6R9uGEw9"
                target="_blank"
              >
                Status
              </a>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Button
              className={classes.btn1}
              fullWidth
              onClick={() => history.push("/signup")}
            >
              Quer participar? Cadastre-se aqui!
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={resetPassword} className={classes.btn2}>
              {loadingResetPassword ? (
                <div className={{ ...classes.loading, margin: 0 }}>
                  <CircularProgress style={{ color: "#A36FFF" }} size={25} />
                </div>
              ) : (
                "Recuperar senha"
              )}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Tooltip title="Participante que cadastrou antes de atualizar o email no paxtu">
              <Button fullWidth onClick={resetEmail} className={classes.btn2}>
                {loadingResetEmail ? (
                  <div className={{ ...classes.loading, margin: 0 }}>
                    <CircularProgress style={{ color: "#A36FFF" }} size={25} />
                  </div>
                ) : (
                  "Sincronizar com paxtu"
                )}
              </Button>
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <Link
              className={classes.copyright2}
              color="inherit"
              href="https://farb.com.br"
              target="_blank"
            >
              Desenvolvido por
              <img src={farb} style={{ maxWidth: 65, margin: "0px 5px" }} />
              {new Date().getFullYear()}
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
