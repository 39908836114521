/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Paper, Grid, Hidden, Typography, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { toast } from "react-toastify";

import Loader from "../../../components/Loader";
import useStyles from "./styles";

import api from "../../../services/api";

export default function Tasks() {
  const classes = useStyles();
  const myTeam = useSelector((state) => state.team.team);
  const [loading, setLoading] = useState(false);

  return (
    <Paper className={clsx(classes.paper, loading && classes.loading)}>
      {loading ? (
        <Loader size={50} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.title}>Pontuação</Typography>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
