import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";
import organizationWhite from "../../../../assets/icons/organizationWhite.svg";
import Modal from "./Modal";

// import { Container } from './styles';

export default function Task({ data, att }) {
  const myTeam = useSelector((state) => state.team.team);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  function level() {
    if (myTeam) {
      if (data.status === "w1_pending" || data.status === "w2_resource") {
        return 1;
      }

      if (data.status === "d1_corrected" || data.status === "d2_recorrected") {
        return 2;
      }

      return 0;
    } else {
      return 2;
    }
  }

  function status() {
    if (data.status) {
      if (data.status === "w1_pending") return "Enviada para correção.";
      if (data.status === "w2_resource") return "Enviada para recorreção.";
      if (data.status === "d1_corrected") return "Corrigida";
      if (data.status === "d2_recorrected") return "ReCorrigida";
    } else {
      return "Não Respondida";
    }
  }

  return (
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <Button
        fullWidth
        style={{ height: "100%", display: "flex", alignItems: "flex-start" }}
        onClick={() => setOpen(true)}
      >
        <Grid
          container
          spacing={1}
          alignContent="space-between"
          style={{ width: "100%", height: "100%" }}
        >
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            style={{ display: "flex" }}
          >
            <div
              className={clsx(
                classes.circle,
                level() === 0
                  ? classes.level0
                  : level() === 1
                  ? classes.level1
                  : classes.level2
              )}
            >
              <div
                className={classes.circleInside}
                style={
                  level() === 2
                    ? {
                        backgroundColor: "#664ACF",
                      }
                    : { backgroundColor: "#B7A7FE" }
                }
              >
                <img src={organizationWhite} className={classes.icon} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center">
            <Typography className={classes.name}>
              {data?.task?.title ?? `Desafio ${data.task.number}`}
            </Typography>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center">
            {myTeam && (
              <>
                <Typography className={classes.status}>{`${status()} ${
                  status() === "Corrigida" ? " - " + data.grade + "%" : ""
                } `}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Button>
      <Modal task={data} open={open} close={() => setOpen(false)} att={att} />
    </Grid>
  );
}
