import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export default makeStyles((theme) => ({
  circle: {
    padding: 10,
    borderRadius: 126,
    width: 126,
    height: 126,

    [theme.breakpoints.down("sm")]: {
      padding: 7,
      borderRadius: 71,
      width: 71,
      height: 71,
    },
  },

  level0: {
    borderWidth: 10,
    borderStyle: "solid",
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "transparent",
    borderLeftColor: "transparent",

    [theme.breakpoints.down("sm")]: {
      borderWidth: 5,
    },
  },

  level1: {
    borderWidth: 10,
    borderStyle: "solid",
    borderTopColor: "#B7A7FE",
    borderRightColor: "#B7A7FE",
    borderBottomColor: "transparent",
    borderLeftColor: "transparent",

    [theme.breakpoints.down("sm")]: {
      borderWidth: 5,
    },
  },

  level2: {
    borderWidth: 10,
    borderStyle: "solid",
    borderTopColor: "#664ACF",
    borderRightColor: "#664ACF",
    borderBottomColor: "#664ACF",
    borderLeftColor: "#664ACF",

    [theme.breakpoints.down("sm")]: {
      borderWidth: 5,
    },
  },

  circleInside: {
    borderRadius: 100,
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
    width: "100%",
    height: "100%",
  },

  icon: {
    width: "100%",
    height: "100%",
  },

  name: {
    fontWeight: 600,
    fontFamily: "Source Sans Pro",
    fontSize: 16,
    color: "#363636",
    textAlign: "center",
  },

  status: {
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    fontSize: 16,
    color: "#363636",
    textAlign: "center",
  },

  button: {
    height: "100%",
  },
}));
