import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export default makeStyles((theme) => ({
  button: {
    marginBottom: 20,
    padding: 16,
    height: "100%",

    fontFamily: "Source Sans Pro",
    color: "#707070",
    fontWeight: 400,
    border: "1px solid #E2E2E2",

    fontSize: 18,
    letterSpacing: 0.54,
    textTransform: "capitalize",

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },

  selected: {
    background: "#664ACF",
    fontWeight: 600,
    "&:hover": {
      background: darken(0.08, "#664ACF"),
    },
    color: "#fff",
    border: "1px solid #664ACF",
  },
}));
