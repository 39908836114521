import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export default makeStyles((theme) => ({
  paper: {
    background: "#FBFBFB",
    boxShadow: "3px 3px 4px #0000000F",
    borderRadius: 0,
    padding: "50px 20px !important",
    minHeight: 200,
  },
  title: {
    width: "100%",
    textAlign: "center",
    fontSize: 28,
    letterSpacing: 0.84,
    color: "#363636",
    opacity: 1,
    fontWeight: 600,
    fontFamily: "Source Sans Pro",

    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  label: {
    textAlign: "left",
    marginRight: 10,
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },

    fontSize: 18,
    letterSpacing: 0.54,
    color: "#363636",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  value: {
    textAlign: "left",
    fontSize: 18,
    letterSpacing: 0.54,
    color: "#363636",
    fontFamily: "Source Sans Pro ,Sans-Serif",
    fontWeight: 300,

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  btn1: {
    background: "#664ACF",
    "&:hover": {
      background: darken(0.08, "#664ACF"),
    },
    borderRadius: 3,
    color: "#fff",
    fontSize: 18,
    textTransform: "capitalize",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  btn2: {
    border: "1px solid #363636",
    borderRadius: 3,
    color: "#363636",
    fontSize: 18,
    textTransform: "capitalize",
    height: "100%",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  left: {
    textAlign: "left",
  },

  preText: {
    fontSize: 18,
    letterSpacing: 0.54,
    width: "100%",
    textAlign: "center",
    color: "#363636",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
}));
