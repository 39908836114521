import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Grid,
  Typography,
  Hidden,
  Button,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { toast } from "react-toastify";
import { differenceInCalendarYears } from "date-fns";
import Loader from "../../../../components/Loader";
import Modal from "../../../../components/Modal";

import useStyles from "./styles";
import AddMember from "./AddMember";
import api from "../../../../services/api";
import history from "../../../../services/history";
import { getMeRequest } from "../../../../store/modules/user/actions";
import ModalCodeTeam from "./CodeTeam";
import ModalCreateTeam from "./CreateTeam";
import ModalFindTeam from "./FindTeam";

import ModalEdit from "./ModalEdit";

export default function Team() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const team = useSelector((state) => state.team.team);
  const loading = useSelector((state) => state.team.loading);
  const me = useSelector((state) => state.user.me);
  const [loading1, setLoading1] = useState(false);
  const [openCodeTeam, setCodeOpenTeam] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [createTeam, setCreateTeam] = useState(false);
  const [addMember, setAddMember] = useState(false);
  const [findTeam, setFindTeam] = useState(false);
  const [open2, setOpen2] = useState(false);

  async function exclue() {
    setLoading1(true);
    setOpenDelete(false);

    try {
      await api.delete(`/team/${team._id}`);

      dispatch(getMeRequest());
      setLoading1(false);
    } catch (error) {
      setLoading1(false);
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro,tente novamente mais tarde"
      );
    }
  }

  function canCreate() {
    if (!me) return false;

    const [day, month, year] = String(me.date).split("/");

    return (
      differenceInCalendarYears(new Date(), new Date(year, month, day)) >= 18
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {team && (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.title}>
                      Informações da Equipe
                    </Typography>
                  </Grid>

                  <Hidden sm smDown>
                    <Grid item xs={12} sm={6}>
                      {me.team && me.team.level === "coordenador" && (
                        <Grid container spacing={2} justify="center">
                          <Grid item>
                            <Button
                              className={classes.btn1}
                              onClick={() => setAddMember(true)}
                            >
                              Adicionar membro
                            </Button>
                          </Grid>
                          {me &&
                            me.team &&
                            me.team.teamId &&
                            me.team.level === "coordenador" && (
                              <Grid item>
                                <Button
                                  className={classes.btn1}
                                  onClick={() => setOpen2(true)}
                                >
                                  Editar equipe
                                </Button>
                              </Grid>
                            )}
                          <Grid item>
                            {loading1 ? (
                              <CircularProgress style={{ color: "#363636" }} />
                            ) : (
                              <Button
                                className={classes.btn2}
                                onClick={() => setOpenDelete(true)}
                              >
                                Deletar equipe
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.label)}>
                          Nome:
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.value)}>
                          {team.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.label)}>
                          Descrição:
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.value)}>
                          {team.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.label)}>
                          Unidade Escoteira:
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.value)}>
                          {team.groupName} - {team.groupNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {team.grade >= 0 ? (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item sm={6}>
                          <Typography className={clsx(classes.label)}>
                            Pontuação
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography className={clsx(classes.value)}>
                            {team.grade > 0
                              ? `${team.grade} pontos`
                              : "Sem pontos"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.label)}>
                          Tipo:
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.value)}>
                          {team?.type || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.label)}>
                          Código da equipe:
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <p className={clsx(classes.value)}>{team.teamId}</p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.label)}>
                          Total de Participantes:
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography className={clsx(classes.value)}>
                          {team.users.length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {team.grade >= 0 ? (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item sm={6}>
                          <Typography className={clsx(classes.label)}>
                            Classificação
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography className={clsx(classes.value)}>
                            {team.classification
                              ? team.classification
                              : "Sem pontos"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

           {/*    {team.grade >= 0 ? (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    className={classes.btn1}
                    onClick={() => history.push("certificate")}
                  >
                    Gerar certificado
                  </Button>
                </Grid>
              ) : (
                ""
              )} */}

              {me.team && me.team.level === "coordenador" && (
                <Hidden md mdUp>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          className={classes.btn1}
                          onClick={() => setAddMember(true)}
                        >
                          Adicionar membro
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        {loading1 ? (
                          <CircularProgress style={{ color: "#363636" }} />
                        ) : (
                          <Button
                            fullWidth
                            className={classes.btn2}
                            onClick={() => setOpenDelete(true)}
                          >
                            Deletar equipe
                          </Button>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          className={classes.btn1}
                          onClick={() => setOpen2(true)}
                        >
                          Editar equipe
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              )}
            </Grid>
          )}

          {!team && (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <Typography className={classes.title}>
                  Informações da Equipe
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.preText}>
                  Parece que você ainda não está em nenhuma equipe. Junte seus
                  amigos, participe ou crie uma equipe e venha participar do
                  JOTA-JOTI.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      fullWidth
                      className={classes.btn1}
                      onClick={() => setFindTeam(true)}
                    >
                      Entrar em equipe
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      fullWidth
                      className={classes.btn1}
                      onClick={() => setCodeOpenTeam(true)}
                    >
                      Entrar em equipe por codigo
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    {canCreate() && (
                      <Button
                        fullWidth
                        className={classes.btn1}
                        onClick={() => setCreateTeam(true)}
                      >
                        Criar equipe
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}

      <Modal
        open={openDelete}
        close={() => setOpenDelete(false)}
        handle={() => exclue()}
        title="Remover equipe"
        text="Tem certeza que deseja remover esta equipe? Os desafios ja respondidos serao perdidos e nao podem ser recuperados."
        bt1Text="Remover"
        bt2Text="Cancelar"
      />

      <ModalCodeTeam open={openCodeTeam} close={() => setCodeOpenTeam(false)} />

      <ModalFindTeam open={findTeam} close={() => setFindTeam(false)} />

      <ModalEdit open={open2} close={() => setOpen2(false)} />

      <ModalCreateTeam open={createTeam} close={() => setCreateTeam(false)} />
      <AddMember open={addMember} close={() => setAddMember(false)} />
    </Paper>
  );
}
