import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

export default function AuthLayout({ children }) {
  const { pathname } = useLocation();
  const classes = useStyles();

  ReactGA.send({ hitType: "pageview", page: pathname });

  return <div className={classes.wrapper}>{children}</div>;
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
