import React from "react";

import { Dialog, Typography, Button, Grid } from "@material-ui/core";

import useStyles from "./styles";

function Modal({ open, close, handle, title, text, bt1Text, bt2Text }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xs"
      style={{ overflowX: "hidden" }}
    >
      <Grid container spacing={2} className={classes.paper}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.text}>{text}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} wrap="nowrap" className={classes.buttons}>
            <Grid item>
              <Button onClick={handle} className={classes.button1}>
                {bt1Text}
              </Button>
            </Grid>

            <Grid item>
              <Button onClick={close} className={classes.button2}>
                {bt2Text}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default Modal;
