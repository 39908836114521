import * as Yup from 'yup';

export default async (data) => {
  const schema = Yup.object().shape({
    userCode: Yup.array().of(
      Yup.string().required('Por favor, digite o codigo do usuario')
    ),
  });

  await schema.validate(data, { abortEarly: false });
};
