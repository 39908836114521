import * as Yup from 'yup';

export default async (data) => {
  const schema = Yup.object().shape({
    name: Yup.string().required('Por favor, digite o nome da sua equipe'),
    description: Yup.string().required(
      'Por favor, digite a Descrição da sua equipe'
    ),
  });

  await schema.validate(data, { abortEarly: false });
};
