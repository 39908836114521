/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Grid,
  Hidden,
  Typography,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { toast } from "react-toastify";

import Loader from "../../../components/Loader";
import useStyles from "./styles";

import api from "../../../services/api";

import Input from "../../../components/Input";

import { Form } from "@unform/web";

export default function Tasks() {
  const [ref, setRef] = useState(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
  });

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");

  async function getData(page = 1) {
    try {
      setLoading(true);

      const response = await api.get("/team", {
        params: {
          page,
          perPage: 10,
          sort: "name",
          filter,
        },
      });

      setData(response.data.data);
      setPagination({
        page: response.data.page,
        totalPages: response.data.totalPages,
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (event, value) => {
    getData(value);
  };

  return (
    <Paper className={clsx(classes.paper, loading && classes.loading)}>
      {loading ? (
        <Loader size={50} />
      ) : (
        <Grid container spacing={2}>
          <Hidden md mdUp>
            <Grid item xs={12}>
              <Typography className={classes.title}>Equipes</Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={6} />

          <Grid item xs={12} md={6}>
            <Form initialData={{ filter }} onSubmit={getData}>
              <Input
                name="filter"
                label="Buscar equipe"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end" onClick={getData}>
                      <Search />
                    </IconButton>
                  ),
                }}
              />
            </Form>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Equipe</TableCell>
                    <TableCell align="right">Grupo</TableCell>
                    <TableCell align="right">Regiao</TableCell>
                    <TableCell align="right">Código de equipe</TableCell>
                    {/* <TableCell align="right">Nota</TableCell>
                    <TableCell align="right">Medalha</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{`${row.groupNumber} - ${row.groupName}`}</TableCell>
                      <TableCell align="right">{row.region}</TableCell>
                      <TableCell align="right">{row._id}</TableCell>
                      {/* <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
