import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export default makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: 50,
    boxShadow: "0px 3px 6px #00000029",

    [theme.breakpoints.down("md")]: {
      padding: 25,
    },

    margin: "20px 0px",
  },
  logo: {
    maxHeight: 180,

    margin: "0px auto",

    [theme.breakpoints.down("md")]: {
      maxHeight: 80,
    },
  },
  description: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.48,
    color: "#363636",
    opacity: 1,
    textAlign: "center",
  },
  descriptionDiv: {
    marginBottom: 30,
  },
  btn1: {
    fontFamily: "Source Sans Pro",
    color: "#fff",
    fontWeight: 600,
    background: "#744EAA",
    fontSize: 20,
    textTransform: "capitalize",
    "&:hover": {
      background: darken(0.08, "#744EAA"),
    },
    /* marginTop: 20,
    marginBottom: 30, */
    margin: "15px 0px",

    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  btn2: {
    color: "#A36FFF",
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    fontSize: 20,

    textTransform: "initial",
  },

  copyright: {
    fontFamily: "Source Sans Pro",
    width: "100%",
    textAlign: "center",
    marginTop: 50,
    fontSize: 20,
    letterSpacing: 0.6,
    color: "#484848",
    fontWeight: 400,
  },

  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0",
    minHeight: "100%",
  },

  white: {
    color: "#fff",
    marginTop: 25,
    fontSize: 16,
  },

  form: {
    width: "100%",
  },

  copyright2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: 20,
    justifyContent: "center",
  },

  sup: {
    color: "#A36FFF",
    width: "100%",
    textAlign: "center",

    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    fontSize: 20,

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
