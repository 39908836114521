import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  drawer: {
    width: 300,
  },

  drawerPaper: {
    width: 300,
  },

  profile: {
    marginTop: 60,
    padding: "35px 50px",
    backgroundColor: "#FBFBFB",
  },

  avatar: {
    width: 41,
    height: 41,
    borderRadius: 41,
  },

  name: {
    letterSpacing: 0.54,
    color: "#363636",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",
    fontSize: 18,
    textTransform: "capitalize",
  },

  level: {
    letterSpacing: 0.48,
    color: "#363636",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    fontSize: 16,
    textTransform: "capitalize",
  },

  copyright: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    letterSpacing: 0.48,
    color: "#484848",
    marginBottom: 30,
  },

  iconOut: {
    color: "#fff",
    transform: "rotateY(180deg)",
    fontSize: 17,
  },

  sup: {
    color: "#664ACF",
    width: "100%",
    textAlign: "center",

    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    fontSize: 20,

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
