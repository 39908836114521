import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppBar, Toolbar, Button, Typography, Hidden } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { signOut } from "../../../../store/modules/auth/actions";
import logoWhite from "../../../../assets/logo2023.svg";

import Modal from "../../../../components/Modal";

import useStyles from "./styles";

export default function Header({ open, trogle }) {
  const dispatch = useDispatch();
  const classes = useStyles(false);
  const [openModal, setOpenModal] = useState(false);

  async function handleOut() {
    dispatch(signOut());
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Hidden md mdUp>
        {open ? (
          <Button onClick={() => trogle()}>
            <Typography className={classes.menuButtonTextWhite}>☰</Typography>
          </Button>
        ) : (
          <Button className={classes.menuButton} onClick={() => trogle()}>
            <Typography className={classes.menuButtonTextWhite}>☰</Typography>
          </Button>
        )}
      </Hidden>
      <Toolbar className={classes.toolbar}>
        <div className={classes.divImg}>
          <img src={logoWhite} className={classes.img} />
        </div>
        <Hidden sm smDown>
          <Button className={classes.button} onClick={() => setOpenModal(true)}>
            <ExitToAppIcon className={classes.icon} />
            <Typography className={classes.text}>Sair</Typography>
          </Button>
        </Hidden>
      </Toolbar>

      <Modal
        open={openModal}
        close={() => setOpenModal(false)}
        handle={() => handleOut()}
        title="Encerrar sessão"
        text="Tem certeza que deseja sair da Plataforma JOTA-JOTI?"
        bt1Text="Sair"
        bt2Text="Cancelar"
      />
    </AppBar>
  );
}
