import * as Yup from 'yup';

export default async (data) => {
  const schema = Yup.object().shape({
    teamName: Yup.string().required('Por favor, digite o nome da equipe'),
    description: Yup.string().required(
      'Por favor, digite a descrição da equipe'
    ),
  });

  await schema.validate(data, { abortEarly: false });
};
