import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export default makeStyles((theme) => ({
  paper: {
    overflowX: "hidden",
    overflowY: "hidden",

    padding: 22,

    [theme.breakpoints.up("sm")]: {
      padding: 55,
    },
  },

  title: {
    width: "100%",
    color: "#363636",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",
    fontSize: 21,

    textAlign: "left",

    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
  },

  text: {
    width: "100%",
    color: "#363636",
    fontWeight: 300,
    fontFamily: "Source Sans Pro",
    fontSize: 18,

    textAlign: "left",

    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
  },

  buttons: {
    justifyContent: "flex-end",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },

  button1: {
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    textAlign: "center",
    color: "#664ACF",

    [theme.breakpoints.up("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
      color: "#FFFFFF",
      backgroundColor: "#664ACF",
      "&:hover": {
        backgroundColor: darken(0.03, "#664ACF"),
      },
    },
  },

  button2: {
    textTransform: "capitalize",
    fontSize: 18,
    color: "#363636",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
      border: "1px solid #363636",
    },
  },

  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  circular: {
    color: "#744EAA",

    [theme.breakpoints.up("sm")]: {
      color: "#fff",
    },
  },

  red: {
    background: "#f44336",

    "&:hover": {
      background: darken(0.08, "#f44336"),
    },
  },
}));
