import React from "react";
import {
  Dialog,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import { Form } from "@unform/web";
import Input from "../../../../components/Input";
import * as Yup from "yup";
import validator from "./validator";
import { toast } from "react-toastify";
import api from "../../../../services/api";

import clsx from "clsx";

const CodeTeam = ({ open, close, att }) => {
  const classes = useStyles();
  const formRef = React.useRef();
  const [user, setUser] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  async function handle(data) {
    formRef.current.setErrors({});

    try {
      setLoading(true);

      await validator(data);

      await api.post("/figure", data);

      toast.success("Figurinha adicionada com sucesso");
      setUser("");
      setLoading(false);
      close();
      att();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error(
          err?.response?.data?.error ||
            "Ocorreu algum erro,tente novamente mais tarde"
        );
      }

      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xs"
      style={{ overflowX: "hidden" }}
    >
      <Grid container spacing={2} className={classes.paper}>
        <Grid item xs={12}>
          <Typography className={classes.title}>Adicionar Contato</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.text}>
            Digite o código ou indicativo do amigo que você quer adicionar o
            contato!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.text}>
            Lembrando: Seu amigo tambem deve pedir para trocar contato com você
            para ser validado!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Form ref={formRef} onSubmit={() => handle({ user })}>
            <Input
              name="user"
              label="Código/Indicativo"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </Form>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => handle({ user })}
            fullWidth
            className={classes.button1}
          >
            {loading ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.circular} size={30} />
              </div>
            ) : (
              "Adicionar"
            )}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => close()}
            fullWidth
            className={clsx(classes.button1, classes.red)}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CodeTeam;
