import React from "react";
import { TextField } from "unform-material-ui";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#664ACF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#664ACF",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#664ACF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#664ACF",
      },
    },
  },
})(TextField);

export default function Input({ name, mask, ...rest }) {
  return (
    <TextField name={name} variant="outlined" fullWidth shrink {...rest} />
  );
}
