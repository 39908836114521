import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";

import CloseIcon from "@material-ui/icons/Close";

import { signOut } from "../../store/modules/auth/actions";

import { getMeSuccess } from "../../store/modules/user/actions";
import { isAdult } from "../../utils/isAdult";

import api from "../../services/api";

const key = "Curso1";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const me = useSelector((state) => state.user.me);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  async function accept() {
    try {
      setLoading(true);

      const response = await api.put(`/user/${me._id}`, {
        courses: {
          ...(me.courses || {}),
          [key]: true,
        },
      });

      dispatch(getMeSuccess(response.data));
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Ocorreu algum erro, tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  }

  function refuse() {
    dispatch(signOut());
  }

  return (
    <Dialog
      onClose={() => {}}
      aria-labelledby="customized-dialog-title"
      open={!me?.courses?.[key] && isAdult(me?.date || new Date())}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={refuse}>
        Cursos Obrigatórios para adultos
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Para participação de adultos no JOTA-JOTI 2023, é necessário ter
          realizado o curso de Cyberbullying ou o Safe From Harm. Caso tenha
          feito o curso em outra oportunidade, não será necessário realizar
          novamente.{" "}
        </Typography>
        <Typography gutterBottom>
          E para aqueles que ainda não realizaram o curso, tem até 14 de outubro
          de 2023 para concluir e se cadastrar na plataforma da atividade. O
          cadastramento na plataforma está condicionado à apresentação do
          certificado de conclusão do curso.{" "}
        </Typography>
        <Typography gutterBottom>
          Você pode encontrar o curso em
          <a
            style={{ marginLeft: 5 }}
            href="https://ead.escoteiros.org.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ead.escoteiros.org.br/.
          </a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={refuse} color="secondary" variant="contained">
          Não concordar
        </Button>
        <Button onClick={() => accept()} color="primary" variant="contained">
          {loading ? <CircularProgress size={25} /> : "Concordar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
