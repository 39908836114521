/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeRequest } from "../../../../../store/modules/user/actions";
import {
  Dialog,
  Typography,
  Button,
  Grid,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import useStyles from "./styles";
import { Form } from "@unform/web";
import Input from "../../../../../components/Input";
import * as Yup from "yup";
import validator from "./validator";
import { toast } from "react-toastify";
import api from "../../../../../services/api";
import clsx from "clsx";

import { Backspace, AddBox } from "@material-ui/icons";

const CodeTeam = ({ open, close }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formRef = React.useRef();
  const [teamCode, setTeamCode] = React.useState([""]);
  const [loading, setLoading] = React.useState(false);
  const team = useSelector((state) => state.team.team);
  const [count, setCount] = React.useState(1);
  const [change, setChange] = React.useState(false);

  async function handle(data) {
    formRef.current.setErrors({});

    try {
      setLoading(true);

      await validator(data);

      const response = await api.post(`/team/batch/${team._id}`, {
        registros: data.userCode,
      });

      response.data.map((item) => {
        toast.error(`Registro:${item.register} - ${item.error}`);
      });

      dispatch(getMeRequest());
      close();

      setLoading(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error(
          err?.response?.data?.error ||
            "Ocorreu algum erro,tente novamente mais tarde"
        );
      }

      setLoading(false);
    }
  }

  React.useEffect(() => {
    setChange(!change);
  }, [teamCode]);

  function handleChange(text, index) {
    let t = teamCode;
    t[index] = text;

    setTeamCode(t);
    setChange(!change);
  }

  React.useEffect(() => {
    if (open) {
      setTeamCode([""]);
      setCount(1);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xs"
      /*       style={{ overflowX: "hidden" }} */
      scroll="body"
    >
      <Grid container spacing={2} className={classes.paper}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            Adicionar membro na equipe
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.text}>
            Digite o registro do escoteiro(sem digito e sem zero à esquerda)
            para traze-lo a sua equipe!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Form ref={formRef} onSubmit={() => handle({ userCode: teamCode })}>
            {[...new Array(count)].map((item, index) => (
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  name={`userCode[${index}]`}
                  label="Registro"
                  defaultValue={teamCode[index]}
                  value={teamCode[index]}
                  onChange={(e) => handleChange(e.target.value, index)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <IconButton
                  style={{ marginLeft: 10 }}
                  onClick={
                    index === 0
                      ? () => {
                          setTeamCode([...teamCode, ""]);
                          setCount(count + 1);
                        }
                      : () => {
                          let newArray = [];
                          teamCode.forEach((item, pos) => {
                            if (index !== pos) {
                              newArray.push(item);
                            }
                          });

                          setTeamCode(newArray);

                          setCount(count - 1);
                        }
                  }
                >
                  {index === 0 ? <AddBox /> : <Backspace />}
                </IconButton>
              </Grid>
            ))}
          </Form>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => handle({ userCode: teamCode })}
            fullWidth
            className={classes.button1}
          >
            {loading ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.circular} size={30} />
              </div>
            ) : (
              "Adicionar"
            )}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => close()}
            fullWidth
            className={clsx(classes.button1, classes.red)}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CodeTeam;
