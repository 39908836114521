import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

const drawerWidth = 360;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: "3px 0px 6px #0000000F",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: "3px 0px 6px #0000000F",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    boxShadow: "3px 0px 6px #0000000F",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    boxShadow: "3px 0px 6px #0000000F",
  },

  //open

  button: {
    background: "#664ACF",
    "&:hover": {
      background: darken(0.03, "#664ACF"),
    },
    border: "2px solid #FFFFFF",
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
  },

  buttonText: {
    color: "#fff",
    fontSize: 20,
    letterSpacing: 0.6,
    textTransform: "capitalize",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
  },

  centerButton: {
    margin: "0 auto",
  },

  center: {
    width: 30,
    height: 30,
    margin: "0 auto",
  },

  itemOpen: {},

  normal: {
    fontSize: 20,
    color: "#363636",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
  },

  selected: {
    fontSize: 20,
    color: "#363636",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",
  },

  profileItem: {
    minHeight: 280,
    marginBottom: 50,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "60px 10px 60px 40px",

    backgroundColor: "#fff",
    background:
      "linear-gradient(45deg, #664ACF 0% 15%, #fff 15% 75%, #664ACF 75% 100%)",
  },

  profileImg: {
    width: 84,
    height: 84,
    borderRadius: 84,
  },

  name: {
    marginTop: 7,
    fontSize: 18,
    color: "#363636",
    textTransform: "capitalize",
    width: "calc(100% - 40px)",
    fontWeight: 600,
    fontFamily: "Source Sans Pro",
  },

  level: {
    marginTop: 5,
    fontSize: 18,
    color: "#363636",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
  },

  copyright: {
    fontSize: 20,
    color: "484848",
    fontWeight: 400,
    fontFamily: "Source Sans Pro",
    letterSpacing: 0.6,
  },

  //close

  listClose: {
    flex: 1,
    padding: 0,
  },

  profileClose: {
    padding: "15px 0",
    justifyContent: "center",
  },

  purpleClose1: {
    backgroundColor: "#664ACF",
    "&:hover": {
      backgroundColor: darken(0.03, "#664ACF"),
    },
  },
  purpleClose2: {
    backgroundColor: "#664ACF",
    "&:hover": {
      backgroundColor: darken(0.03, "#664ACF"),
    },
  },

  purpleClose3: {
    backgroundColor: "#664ACF",
    "&:hover": {
      backgroundColor: darken(0.03, "#664ACF"),
    },
  },

  purpleClose4: {
    backgroundColor: "#664ACF",
    "&:hover": {
      backgroundColor: darken(0.03, "#664ACF"),
    },
  },

  openTab: {
    position: "fixed",
    width: 100,
    zIndex: 9,
    borderRadius: "0px 10px 10px 0px",
  },

  sup: {
    color: "#664ACF",
    width: "100%",
    textAlign: "center",

    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    fontSize: 20,

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
