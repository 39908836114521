import React from "react";
import { Select as SelectMt } from "unform-material-ui";
import { MenuItem } from "@material-ui/core";

export default function Select({ name, mask, options, ...rest }) {
  return (
    <div>
      <SelectMt
        name={name}
        variant="outlined"
        fullWidth
        {...rest}
        style={{ width: "100%" }}
      >
        {options.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </SelectMt>
    </div>
  );
}
