import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "-webkit-fill-available",
  },
}));

// import { Container } from './styles';

export default function Loader({ size }) {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      <CircularProgress style={{ color: "#664ACF" }} size={size || 40} />
    </div>
  );
}
