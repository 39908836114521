/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";

import ReactToPrint from "react-to-print";
import history from "../../../services/history";

import useStyles from "./styles";
import Certificate from "../../../components/Certificate";

import PersonalCertificate from "../../../components/PersonalCertificate";

import api from "../../../services/api";

export default function Profile() {
  const ref = useRef(null);
  const classes = useStyles();
  const { team, loading: loadingTeam } = useSelector((state) => state.team);
  const { me } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [classification, setClassification] = useState(null);
  const [showMy, setShowMy] = useState(false);

  const coordinatorName = (
    team?.users?.find((user) => user?.team?.level === "coordenador")?.name || ""
  ).split(" ");

  const coordinator = `${coordinatorName[0]} ${
    coordinatorName[coordinatorName.length - 1]
  }`;

  async function getData() {
    try {
      setLoading(true);

      if (!team) {
        /*  setShowMy(true); */

        setShow(true);

        return;

        /*  toast.error(
          "É necessario estar em uma equipe para conseguir o certificado!"
        );

        return history.push("/dashboard"); */
      }

      const response = await api.get(
        `/super/certificate/team/${team?._id || undefined}`
      );

      const { classification, show } = response.data;

      if (show) {
        setShow(true);

        setClassification(classification || "Sem pontos");
      }
    } catch (err) {
      console.log(err);

      toast.error(
        err?.response?.data?.error ||
          "Ocorreu algum erro, tente novamente mais tarde"
      );

      history.push("/dashboard");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!loadingTeam) {
      getData();
    }
  }, [team, loadingTeam]);

  const indicativo = me?.indicativo;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={classes.title}>
            Certificado {showMy ? "Pessoal" : "de Equipe"}
          </Typography>
        </Grid>

        {loading || loadingTeam ? (
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Loader />
          </Grid>
        ) : (
          <>
            {show ? (
              <>
                <Grid item xs={12} ref={ref}>
                  <Certificate
                    name={team?.name}
                    coordinator={coordinator}
                    classification={classification}
                  />
                </Grid>
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {classification && classification !== "Sem pontos" && (
                          <Button className={classes.btn1}>Gerar pdf</Button>
                        )}
                      </Grid>
                    );
                  }}
                  content={() => ref.current}
                />
              </>
            ) : (
              <Grid item xs={12}>
                <Typography
                  className={classes.name}
                  style={{ fontWeight: "bold", color: "#000" }}
                >
                  Certificados ainda não foram liberados!
                </Typography>
              </Grid>
            )}

            {show && !!team && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/*   <Button
                  className={classes.btn1}
                  onClick={() => setShowMy((prev) => !prev)}
                >{`Certificado ${showMy ? "de equipe" : "pessoal"}`}</Button> */}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Paper>
  );
}
