import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  padding: {
    flex: 1,
  },
  toolbar: {
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  copyright: {
    width: '100%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',

    justifyContent: 'flex-end',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));
