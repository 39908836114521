/* eslint-disable no-sparse-arrays */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Dialog, Typography, Grid, Button } from "@material-ui/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import clsx from "clsx";
import api from "../../../../../services/api";

import Input from "../../../../../components/Input";

import useStyles from "./styles";
import Loader from "../../../../../components/Loader";

export default function Modal({ task, open, close, att }) {
  const classes = useStyles();
  const formRef = useRef();
  const me = useSelector((state) => state.user.me);
  const myTeam = useSelector((state) => state.team.team);
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingSend, setLoadingSend] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [error2, setError2] = React.useState(false);

  async function submit(e) {
    setError(false);

    if (data.status !== "d1_corrected") {
      const responses = Object.values(e);

      let t = [];

      responses.map((response, index) => {
        t.push({
          taskItem: data.taskItens[index]._id,
          answer: response,
        });
      });

      setLoadingSend(true);

      try {
        let flag = false;
        data.taskItens.map((item, index) => {
          if (item.camp && t[index].answer === "") {
            flag = true;
          }
        });

        if (!flag) {
          await api.post(`/answers/${myTeam.teamId}`, {
            task: data._id,
            taskItens: t,
            groupRegion: me.region,
          });

          toast.success("Tarefa respondida com sucesso.");
          att();
        } else {
          setError2(true);
        }

        setLoadingSend(false);
      } catch (error) {
        setLoadingSend(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Ocorreu algum erro, tente novamente mais tarde");
        }
      }
    } else {
      if (!e.resourceText) {
        setError(true);
      } else {
        try {
          setLoadingSend(true);
          await api.post(`/answers/${myTeam.teamId}/resource`, {
            answerId: data.answerId,
            resourceText: e.resourceText,
          });

          setLoadingSend(false);
          toast.success("Pedida recorreção com sucesso.");
          att();
        } catch (error) {
          setLoadingSend(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Ocorreu algum erro, tente novamente mais tarde");
          }
        }
      }
    }
  }

  async function getTask() {
    setLoading(true);
    try {
      let response;

      setData(null);

      if (myTeam) {
        response = await api.get(`tasks/detail/${myTeam.teamId}`, {
          params: {
            type: myTeam.type,
            taskId: task.task._id,
          },
        });
      } else {
        response = await api.get(`tasks/detail/noTeam`, {
          params: {
            type: "Geral",
            taskId: task.task._id,
          },
        });
      }

      setData(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      close();
      toast.error("Tarefa não liberada!");
    }
  }

  React.useEffect(() => {
    if (open) {
      getTask();
      setError2(false);
    }
  }, [, open]);

  function status() {
    if (data.status) {
      if (data.status === "w1_pending") return "Enviada para correção.";
      if (data.status === "w2_resource") return "Enviada para recorreção.";
      if (data.status === "d1_corrected") return "Corrigida";
      if (data.status === "d2_recorrected") return "ReCorrigida";
    } else {
      return "Não Respondida";
    }
  }

  return (
    <Dialog open={open} onClose={() => close()} maxWidth="md">
      <Grid container spacing={2} className={classes.paper}>
        <Grid item justify="flex-start" xs={12}>
          <Typography className={classes.title}>
            Desafio {data && data.number}
          </Typography>
        </Grid>
        <Grid item justify="flex-start" xs={12}>
          <Typography className={classes.subTitle}>
            {data && data.title}
          </Typography>
        </Grid>

        {data && data.mediaLink && (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReactPlayer url={data.mediaLink} />
          </Grid>
        )}
        {loading ? (
          <div style={{ flex: 1, height: 200 }}>
            <Loader />
          </div>
        ) : (
          <>
            {data && data.mediaLink && (
              <Grid item xs={12} justify="flex-start">
                <Typography className={classes.label}>Media link</Typography>
              </Grid>
            )}

            <Grid item xs={12} justify="flex-start">
              <Typography className={classes.label}>O que fazer?</Typography>
            </Grid>

            <Grid item xs={12} justify="flex-start">
              {data && (
                <div
                  className={classes.value}
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              )}
            </Grid>

            {data && (
              <Grid item xs={12} justify>
                <Form ref={formRef} onSubmit={submit} className={classes.form}>
                  <Grid container spacing={2}>
                    {[...data.taskItens].map((item, index) => (
                      <Grid item xs={12}>
                        <Typography
                          className={classes.subTitle}
                          style={{ fontSize: 16, marginBottom: 5 }}
                        >
                          {item.usersText}
                        </Typography>

                        {item.answer ? (
                          <Input
                            name={`${index}`}
                            disabled
                            defaultValue={item.answer}
                            label={item.campName || "sem nome"}
                            style={{
                              visibility: item.camp ? "visible" : "hidden",
                              marginBottom: item.camp ? 10 : 0,
                              height: item.camp ? "auto" : 0,
                            }}
                          />
                        ) : (
                          <>
                            <Input
                              name={`${index}`}
                              label={item.campName || "sem nome"}
                              retquired={item.camp}
                              style={{
                                visibility: item.camp ? "visible" : "hidden",
                                height: item.camp ? "auto" : 0,
                                marginBottom: item.camp ? 10 : 0,
                              }}
                            />
                          </>
                        )}
                      </Grid>
                    ))}

                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Typography className={classes.status}>
                            Status:
                            <Typography className={classes.answer}>
                              {status()}
                            </Typography>
                          </Typography>
                        </Grid>
                        {data.answered && (
                          <Grid item xs={12}>
                            <Typography className={classes.status}>
                              Puntuação:
                              <Typography className={classes.answer}>
                                {" "}
                                80/100
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Typography className={classes.status}>
                            Autor:
                            <Typography className={classes.answer}>
                              {" "}
                              {data.author}
                            </Typography>
                          </Typography>
                        </Grid>

                        {data.agentAlert && (
                          <Grid item xs={12}>
                            <Typography className={classes.status}>
                              Mensagem do Corretor:
                              <Typography className={classes.answer}>
                                {data.agentAlert}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}

                        {data.grade && (
                          <Grid item xs={12}>
                            <Typography className={classes.status}>
                              Nota:
                              <Typography className={classes.answer}>
                                {` ${data.grade}%`}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}

                        {data.resourceText && (
                          <Grid item xs={12}>
                            <Input
                              name="resourceText"
                              disabled
                              ini
                              value={data.resourceText}
                              defaultValue={data.resourceText}
                              style={{ marginTop: 20 }}
                              label="Mensagem para recorreção"
                              multiline
                              error
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {me.team && (
                      <Grid item xs={12}>
                        {loadingSend ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Loader />
                          </div>
                        ) : (
                          <>
                            {!data.status && me.team.level === "coordenador" && (
                              <>
                                {error2 && (
                                  <Typography color="error">
                                    Todos os campos com respostao são
                                    obrigatórios
                                  </Typography>
                                )}

                                <Button
                                  className={classes.button}
                                  type="submit"
                                >
                                  {data.status === "d1_corrected"
                                    ? "Pedir recorreção"
                                    : "Enviar"}
                                </Button>

                                {/*   {data.status === "d1_corrected" && (
                                    <>
                                      <Input
                                        name="resourceText"
                                        style={{ marginTop: 20 }}
                                        label="Mensagem para recorreção"
                                        multiline
                                        error
                                      />

                                      {error && (
                                        <Typography color="error">
                                          Motivo para recorreção obrigatório.
                                        </Typography>
                                      )}
                                    </>
                                  )} */}
                              </>
                            )}

                            <Button
                              style={{ marginLeft: 10 }}
                              className={clsx(classes.button, classes.red)}
                              type="button"
                              onClick={() => close()}
                            >
                              Fechar
                            </Button>
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Form>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Dialog>
  );
}
